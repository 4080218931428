import React from 'react'
import colors from '../../Colors.scss'

const DoneIcon = ({ width = '64', height = '64', color = colors.color_sidebar_icon }) => {
  return (
      <svg
          width={width}
          height={height}
          viewBox="0 -0.5 21 21"
          fill={color}
          xmlns="http://www.w3.org/2000/svg"
      >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Dribbble-Light-Preview" transform="translate(-139.000000, -400.000000)" fill={color}>
                      <g id="icons" transform="translate(56.000000, 160.000000)">
                          <path d="M97.23065,248.168 L92.7776,252.408 C92.366,252.8 91.69925,252.8 91.28765,252.408 L89.0627,250.289 C88.65215,249.899 88.65215,249.266 89.0627,248.875 C89.4722,248.485 90.1379,248.485 90.5474,248.875 L91.2908,249.583 C91.7003,249.973 92.36495,249.973 92.7755,249.583 L95.74595,246.754 C96.15545,246.363 96.8201,246.363 97.23065,246.754 C97.6412,247.144 97.6412,247.777 97.23065,248.168 M101.9,240 L85.1,240 C83.93975,240 83,240.895 83,242 L83,258 C83,259.104 83.93975,260 85.1,260 L101.9,260 C103.06025,260 104,259.104 104,258 L104,242 C104,240.895 103.06025,240 101.9,240"></path>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
  )
}

export default DoneIcon
