import React from 'react'
import colors from '../../Colors.scss'

const DownloadIcon = ({ width = '24', height = '24', color = colors.color_sidebar_icon }) => {
  return (
      <svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill={color}
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              d="M3,12.3v7a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2v-7"
              fill="none"
              stroke={color}
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2">

          </path>
          <polyline
              fill="none"
              points="7.9 12.3 12 16.3 16.1 12.3"
              stroke={color}
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2">

          </polyline>
          <line
              fill="none"
              stroke={color}
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              x1="12" x2="12" y1="2.7" y2="14.2"
          >

          </line>
      </svg>
  )
}

export default DownloadIcon
