import './styles.scss'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'

export const CookieBanner = () => {
  const userAcceptedCookies = !!localStorage.getItem('acceptedCookies')
  const [language, setLanguage] = useState('en')
  const [isShow, setIsShow] = useState(!userAcceptedCookies)

  const renderDesc = (() => {
    switch (language) {
      case 'de':
        return 'Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. Weitere Informationen zu Cookies erhalten Sie in unserer Datenschutzerklärung.'
      case 'en':
        return 'In order to design our website optimally for you and continuously improve it, we use cookies. By continuing to use the website, you consent to the use of cookies. You can find more information about cookies in our privacy policy.'
      case 'fr':
        return 'Afin de concevoir notre site web de manière optimale pour vous et de l\'améliorer en continu, nous utilisons des cookies. En continuant à utiliser le site, vous consentez à l\'utilisation de cookies. Vous pouvez trouver plus d\'informations sur les cookies dans notre politique de confidentialité.'
      case 'it':
        return 'Per poter progettare il nostro sito web in modo ottimale per voi e migliorarlo continuamente, utilizziamo i cookie. Continuando a utilizzare il sito web, acconsentite all\'uso dei cookie. Ulteriori informazioni sui cookie sono disponibili nella nostra informativa sulla privacy.'
      default:
        return ''
    }
  })()

  const renderButtonText = (() => {
    switch (language) {
      case 'de':
        return 'Ich stimme zu!'
      case 'en':
        return 'I agree!'
      case 'fr':
        return 'Je suis d\'accord !'
      case 'it':
        return 'Sono d\'accordo!'
      default:
        return ''
    }
  })()

  if (!isShow) return <></>

  return (
        <div className='banner'>
            <p>{renderDesc}</p>
            <div onClick={() => {
              localStorage.setItem('acceptedCookies', 'true')
              setIsShow(false)
            }}>{renderButtonText}</div>
            {/* <FormControl size='small' id='form-control'> */}
            {/*    <Select */}
            {/*        value={language} */}
            {/*        className='banner__select' */}
            {/*        sx={{ color: '#fff', border: '1px solid #fff', paddingLeft: '15px' }} */}
            {/*        onChange={(e) => { */}
            {/*          setLanguage(e.target.value) */}
            {/*        }} */}
            {/*    > */}
            {/*        <MenuItem value="de">de</MenuItem> */}
            {/*        <MenuItem value="en">en</MenuItem> */}
            {/*        /!* <MenuItem value="fr">fr</MenuItem> *!/ */}
            {/*        /!* <MenuItem value="it">it</MenuItem> *!/ */}
            {/*    </Select> */}
            {/* </FormControl> */}
        </div>
  )
}
