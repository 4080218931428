import React from 'react'
import colors from '../../Colors.scss'

const ThreeDotsIcon = ({ width = '15', height = '3', color = colors.color_sidebar_icon }) => {
  return (
      <svg
          width={width}
          height={height}
          viewBox="0 0 15 3"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <ellipse
              cx="13.5"
              cy="1.5"
              rx="1.5"
              ry="1.5"
              transform="rotate(-180 13.5 1.5)"
              fill={color}
          />
          <ellipse
              cx="7.5"
              cy="1.5"
              rx="1.5"
              ry="1.5"
              transform="rotate(-180 7.5 1.5)"
             fill={color}
          />
          <ellipse
              cx="1.5"
              cy="1.5"
              rx="1.5"
              ry="1.5"
              transform="rotate(-180 1.5 1.5)"
              fill={color}
          />
      </svg>

  )
}

export default ThreeDotsIcon
