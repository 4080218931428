import React from 'react'
import colors from '../../../Colors.scss'

const GreaterThanIcon = ({ width = '24', height = '24', color = colors.color_sidebar_icon }) => {
  return (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M9 18L15 12L9 6"
              stroke={color}
              strokeOpacity="0.5"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"/>
    </svg>
  )
}

export default GreaterThanIcon
