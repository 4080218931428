import React from 'react'
import logo from '../../assets/cust/images/logo.png'
import { useNavigate } from 'react-router-dom'

export default function HeadLogo () {
  const navigate = useNavigate()
  return (
        <div className='logo-container'
             onClick={() => {
               localStorage.removeItem('evId')
               navigate('/')
             }}

        >
            {/* <a target='_blank' href="https://www.colorectalsurgery.eu/" rel="noreferrer"> */}
                <img src={logo} alt="logo" height={90}/>
        {/* </a> */}
        {/*    <h2 */}
        {/*        onClick={() => { */}
        {/*          localStorage.removeItem('evId') */}
        {/*          navigate('/') */}
        {/*        }} */}
        {/*    >ECC 2024</h2> */}
        </div>
  )
}
