import { TextField } from '@material-ui/core'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Alert from '@mui/material/Alert'
import InputAdornment from '@mui/material/InputAdornment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import setAuthToken from '../../auth/SetAuthToken'

// src
import bg from '../../assets/cust/images/background.png'
import { GradiantBtn } from '../../assets/muiStyles/Button-style'
import { usePostSignInMutation } from '../../redux/api/authApi/authApi'
import {
  addRefreshToken,
  addToken,
  addUserId
} from '../../redux/slices/authSlice/authSlice'
import { addRefreshTokenExpiration } from '../../redux/slices/globalValue/globalValue'
import './SignInPage.scss'

export default function SignInPage () {
  // hooks
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  // env
  const refresh_token_expiration =
    process.env.REACT_APP_REFRESH_TOKEN_EXPIRATION

  // redux
  const evSlug = useSelector(state => state.globalValueReducer.slug)
  const eventId = useSelector(state => state.globalValueReducer.eventId)
  const refresh_token_expirationRedux = useSelector(
    state => state.globalValueReducer.refresh_token_expiration
  )
  // state
  const [credientials, setCredientials] = useState({
    email: '',
    password: ''
  })
  // const [rememberMe, setRememberMe] = useState('false')
  const [errorText, setError] = useState('')
  const [passwordEye, setPasswordEye] = useState(false)
  const locationEdit = location.pathname.split('/')
  // query
  const [postSignIn, isLoading] = usePostSignInMutation(credientials)
  useEffect(() => {
    if (
      window.location.href.includes('/sign-in') ||
      (window.location.href.includes('/user/editing-profile/') &&
        refresh_token_expirationRedux === 'expired')
    ) {
      refresh_token_expirationRedux &&
        toast(t('pages.signinpage.toast-your-session-has-expired'), {
          toastId: 'session-expired-message'
        })
    }
  }, [])
  // useEffect(() => {
  //   error && toast.error(<FormattedMessage id="toast-error-invalid-credentials"
  //                                   defaultMessage="invalid credentials!"/>, {
  //     position: 'top-center'
  //   })
  // }, [error])
  const handleSubmit = async e => {
    e.preventDefault()
    if (credientials.email.length <= 0 || credientials.password.length <= 0) {
      return toast.error(t('pages.signinpage.toast-fields-required'), {
        position: 'top-center'
      })
    }
    postSignIn(credientials)
      .unwrap()
      .then(response => {
        if (response && response.access_token) {
          dispatch(addToken(response.access_token))
          dispatch(addRefreshToken(response.refresh_token))
          dispatch(addUserId(response.user.pk))
          dispatch(
            addRefreshTokenExpiration(+new Date() + +refresh_token_expiration)
          )
          setAuthToken(response.access_token)
          if (locationEdit[2] === 'editing-profile') {
            navigate('/user/editing-profile/')
          } else {
            // eventId
            //   ? navigate('/events/' + eventId + '/' + evSlug + '/agenda/')
            //   :
            navigate('/')
          }
        }
      })
      .catch(() => {
        toast.error(t('pages.signinpage.toast-invalid-credentials'), {
          position: 'top-center'
        })
      })
  }
  const handleChange = e => {
    setCredientials({ ...credientials, [e.target.name]: e.target.value })
  }

  return (
    <>
      <ToastContainer />
      <div className="sign-in-main" style={{ position: 'relative' }}>
        <img
          src={bg}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: '0'
          }}
          alt=""
        />
        <form onSubmit={handleSubmit} noValidate>
          <div
            className="sign-in-form"
            style={{ zIndex: '11', position: 'relative' }}
          >
            <p className="welcome-msg">{t('pages.signinpage.welcome')}</p>
            <p className="desc">
              {t('pages.signinpage.enter-your-credentials')}
            </p>

            <TextField
              style={{ marginTop: '50px' }}
              error={!!errorText}
              // helperText= 'Please Enter Your Email'
              value={credientials.email}
              onFocus={() => setError('')}
              onChange={e => handleChange(e)}
              placeholder={t(
                'pages.signinpage.placeholder-enter-your-email-address'
              )}
              type="email"
              name="email"
              variant="outlined"
              required
            />

            <TextField
              style={{ marginTop: '24px', borderRadius: '8px' }}
              error={!!errorText}
              onFocus={() => setError('')}
              // helperText= 'Please Enter Your Email'
              value={credientials.password}
              onChange={e => handleChange(e)}
              name="password"
              // inputProps={{ pattern: "^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$"}}
              placeholder={t(
                'pages.signinpage.placeholder-enter-your-password'
              )}
              type={!passwordEye ? 'password' : 'text'}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {passwordEye ? (
                      <VisibilityIcon
                        onClick={() => setPasswordEye(!passwordEye)}
                      />
                    ) : (
                      <VisibilityOffIcon
                        onClick={() => setPasswordEye(!passwordEye)}
                      />
                    )}
                  </InputAdornment>
                )
              }}
              required
            />

            <br />
            {errorText ? (
              <Alert
                className="error-message"
                style={{}}
                variant="outlined"
                severity="error"
              >
                {errorText}
              </Alert>
            ) : (
              ''
            )}
            <div className="sign-in-option">
              <p className="forgot-pass" onClick={() => navigate('/signup')}>
                {process.env.REACT_APP_SIGNUP_ENABLED === 'true' &&
                  t('pages.signinpage.not-yet-registered')}
              </p>
              <p
                className="forgot-pass"
                onClick={() => {
                  navigate('/password/forgot')
                }}
              >
                {t('pages.signinpage.forgot-password')}
              </p>
            </div>
            <GradiantBtn type="submit" loading={isLoading} variant="contained">
              {t('pages.signinpage.sign-in')}
            </GradiantBtn>
            {process.env.REACT_APP_SIGNUP_ENABLED === 'true' && (
              <GradiantBtn
                style={{ marginTop: '15px' }}
                variant="contained"
                onClick={() => {
                  navigate('/signup')
                }}
              >
                {t('pages.signinpage.sign-up')}
              </GradiantBtn>
            )}
          </div>
          <br />
        </form>
        <div className="details-container">
          <div className="homepage-section-2-text">
            <p className="heading">{t('pages.signinpage.page-heading')}</p>
            <p className="para">{t('pages.signinpage.page-para')}</p>
          </div>
        </div>
      </div>
    </>
  )
}
