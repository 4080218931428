import { defaultApi } from '../splitApi'

export const newsApi = defaultApi.injectEndpoints({
  endpoints: build => ({
    getNews: build.query({
      async queryFn (
        {
          eventId,
          searchValue = '',
          orderBy = '',
          order = '',
          limit = '',
          offset = ''
        },
        { signal, dispatch, getState },
        extraOptions,
        fetchWithBQ
      ) {
        if (!searchValue?.length > 0) {
          const result = await fetchWithBQ(
            `base/events/${eventId}/news/${
              orderBy && `?order=${order}`
            }${orderBy}${+limit !== 0 ? `&limit=${limit}` : ''}${
              offset && `&offset=${offset}`
            }`
          )
          return result
        } else {
          const result = await fetchWithBQ(
            `/base/events/${eventId}/news/?search=${searchValue}`
          )
          return result
        }
      }
    }),
    getNewsDetail: build.query({
      query: ({ eventId, newsId }) => `/base/events/${eventId}/news/${newsId}/`
    })
  })
})

export const { useGetNewsQuery, useGetNewsDetailQuery } = newsApi
