import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// src
import CircleFilled from '../../../SVG/global/CircleFilled'
import { useAuthValue } from '../../../hooks/redux/reduxHooks'
import noPhoto from '../../../images/noPhoto.jpg'
import { useGetUserAccessQuery } from '../../../redux/api/userApi/userApi'
import {
  addEventId,
  addMenu,
  addSlug
} from '../../../redux/slices/globalValue/globalValue'
import './Banner.scss'
import { useGetNewsQuery } from '../../../redux/api/newsApi/newsApi'

const Banner = ({ bannerEvent }) => {
  // hooks
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const privacyType = process.env.REACT_APP_PRIVACY_FLAG
  const token = useSelector(state => state.authReducer.token)

  const { userId } = useAuthValue()
  const { t, i18n } = useTranslation()

  const { data: userAccess } = useGetUserAccessQuery(bannerEvent[0]?.id, {
    skip: !bannerEvent[0]?.id
  })

  const { data: lastNews } = useGetNewsQuery({
    eventId: bannerEvent[0].id,
    orderBy: '-id',
    limit: 1
  }, {
    skip: !userAccess?.event_features?.news_enabled || !bannerEvent[0]?.id
  })

  // state
  let startTime, endTime
  try {
    startTime =
      bannerEvent?.items[0]?.schedule_begin
        .substring(0, 16)
        .split('T')[0]
        .split('-')
        .reverse()
        .join('-') +
      ',' +
      bannerEvent?.items[0]?.schedule_begin.substring(0, 16).split('T')[1]
  } catch (e) {
    startTime = 0
  }
  try {
    endTime =
      bannerEvent?.items[0]?.schedule_end
        .substring(0, 16)
        .split('T')[0]
        .split('-')
        .reverse()
        .join('-') +
      ',' +
      bannerEvent?.items[0]?.schedule_end.substring(0, 16).split('T')[1]
  } catch (e) {
    endTime = 0
  }

  const [livebtn, setLivebtn] = useState(0)

  useEffect(() => {
    const { items } = bannerEvent
    try {
      const currentTime = new Date().getTime()
      const begin = new Date(
        items[0]?.schedule_begin?.substring(0, 16)
      )?.getTime()
      const end = new Date(items[0]?.schedule_end?.substring(0, 16))?.getTime()
      if (currentTime > begin && currentTime < end) {
        setLivebtn(1)
      } else if (currentTime < begin) {
        setLivebtn(2)
      } else {
        setLivebtn(3)
      }
    } catch (e) {
      setLivebtn(3)
    }
  }, [bannerEvent])

  return (
    <>
      {!!bannerEvent?.length && (
        <div
          onClick={() => {
            dispatch(addEventId({ eventId: bannerEvent[0]?.id }))
            dispatch(addSlug(bannerEvent[0]?.meta?.slug))
            dispatch(addMenu('agenda'))
            if (privacyType === 'PUBLIC' && !token) {
              navigate('/sign-in')
            } else if (token) {
              const permissions = userAccess?.event_features

              if (permissions) {
                const availablePages = {
                  news_enabled: `/events/${bannerEvent[0].id}/${bannerEvent[0].meta.slug}/news/`,
                  stream_player_enabled: `/events/${bannerEvent[0].id}/${bannerEvent[0].meta.slug}/stream/`,
                  agenda_enabled: `/events/${bannerEvent[0].id}/${bannerEvent[0].meta.slug}/agenda/`,
                  participants_enabled: `/events/${bannerEvent[0].id}/${bannerEvent[0].meta.slug}/participants/`,
                  chat_enabled: `/events/${bannerEvent[0].id}/${bannerEvent[0].meta.slug}/inbox/`,
                  polls_enabled: `/events/${bannerEvent[0].id}/${bannerEvent[0].meta.slug}/polls/`,
                  questions_enabled: `/events/${bannerEvent[0].id}/${bannerEvent[0].meta.slug}/questions/`,
                  media_library_enabled: `/events/${bannerEvent[0].id}/${bannerEvent[0].meta.slug}/downloads/`,
                  hotline_enabled: `/events/${bannerEvent[0].id}/${bannerEvent[0].meta.slug}/hotline/`,
                  posters_enabled: `/events/${bannerEvent[0].id}/${bannerEvent[0].meta.slug}/posters/`,
                  exhibition_enabled: `/events/${bannerEvent[0].id}/${bannerEvent[0].meta.slug}/exhibition/`
                }

                if (permissions.news_enabled) {
                  if (lastNews && lastNews.items && lastNews.items.length > 0) {
                    navigate(
                      `/events/${bannerEvent[0].id}/${bannerEvent[0].meta.slug}/news/${lastNews.items[0].id}/details/`
                    )
                    return
                  }
                  navigate(availablePages.news_enabled)
                  return
                }

                const firstAvailablePage = Object.keys(permissions).find(
                  key => {
                    return permissions[key] && availablePages[key]
                  }
                )

                if (firstAvailablePage) {
                  navigate(availablePages[firstAvailablePage])
                }
                const getAvailablePages = event => ({
                  stream_player_enabled: `/events/${event.id}/${event.meta.slug}/stream/`,
                  agenda_enabled: `/events/${event.id}/${event.meta.slug}/agenda/`,
                  participants_enabled: `/events/${event.id}/${event.meta.slug}/participants/`,
                  chat_enabled: `/events/${event.id}/${event.meta.slug}/inbox/`,
                  polls_enabled: `/events/${event.id}/${event.meta.slug}/polls/`,
                  questions_enabled: `/events/${event.id}/${event.meta.slug}/questions/`,
                  media_library_enabled: `/events/${event.id}/${event.meta.slug}/downloads/`,
                  hotline_enabled: `/events/${event.id}/${event.meta.slug}/hotline/`,
                  posters_enabled: `/events/${event.id}/${event.meta.slug}/posters/`,
                  exhibition_enabled: `/events/${event.id}/${event.meta.slug}/exhibition/`,
                  news_enabled: `/events/${event.id}/${event.meta.slug}/news/`
                })

                if (bannerEvent && bannerEvent[0] && permissions) {
                  const availablePages = getAvailablePages(bannerEvent[0])
                  const firstAvailablePageKey = Object.keys(permissions).find(
                    key => permissions[key]
                  )
                  const firstAvailablePagePath =
                    availablePages[firstAvailablePageKey]

                  if (
                    firstAvailablePagePath &&
                    window.location.pathname !== firstAvailablePagePath
                  ) {
                    navigate(firstAvailablePagePath, {
                      state: { id: bannerEvent[0].id }
                    })
                  }
                }
              }
            }
          }}
          className="homepage-container"
        >
          {bannerEvent[0]?.image?.meta?.download_url ? (
            <img
              className="banner-img"
              src={bannerEvent[0]?.image?.meta?.download_url}
              width="100%"
              height="100%"
            />
          ) : (
            <img
              src={noPhoto}
              className="banner-img"
              width="100%"
              height="100%"
              alt="event-card"
            />
          )}

          <div className="banner-play-btn ">
            {/* <PlayBtn/> */}
            <p className="black-rounded-btn-entry">{t('main-banner-button')}</p>
            {!userId && process.env.REACT_APP_SIGNUP_ENABLED === 'true' && (
              <p
                onClick={e => {
                  e.stopPropagation()
                  navigate('/signup')
                }}
                className="black-rounded-btn-entry"
              >
                {t('pages.signinpage.sign-up')}
              </p>
            )}
          </div>
          <div className="details-container">
            <div className="homepage-section-2-text">
              {livebtn === 1 ? (
                <button className="banner-live-now-btn">
                  {' '}
                  <CircleFilled />
                  {t('pages.homepage.banner.live-now')}
                </button>
              ) : livebtn === 2 ? (
                <p className="time">{startTime + ' - ' + endTime}</p>
              ) : livebtn === 3 ? (
                <p className="time ">
                  {t('pages.homepage.banner.streamed-on')}{' '}
                  {bannerEvent[0]?.schedule_end?.substring(0, 16)}
                </p>
              ) : (
                ''
              )}
              <p className="heading">{bannerEvent[0]?.title}</p>
              <p className="para">{bannerEvent[0]?.hero_text}</p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default Banner
