import React from 'react'
import colors from '../../../Colors.scss'

const Briefcase = ({ width = '24', height = '24', color = colors.color_sidebar_icon }) => {
  return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.0008 6V5.2C8.0008 3.43 8.0008 2 11.2008 2H12.8008C16.0008 2 16.0008 3.43 16.0008 5.2V6M14.0008 14.02C14.0008 14.01 14.0008 14.01 14.0008 14V13C14.0008 12 14.0008 12 13.0008 12H11.0008C10.0008 12 10.0008 12 10.0008 13V14.03M14.0008 14.02C14.0008 15.11 13.9908 16 12.0008 16C10.0208 16 10.0008 15.12 10.0008 14.03M14.0008 14.02C16.7008 13.68 19.3408 12.68 21.6508 11M10.0008 14.03C7.4108 13.74 4.8708 12.81 2.6208 11.27M8.0008 22H16.0008C20.0208 22 20.7408 20.39 20.9508 18.43L21.7008 10.43C21.9708 7.99 21.2708 6 17.0008 6H7.0008C2.7308 6 2.0308 7.99 2.3008 10.43L3.0508 18.43C3.2608 20.39 3.9808 22 8.0008 22Z"
                stroke={color} strokeOpacity="0.5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>
  )
}

export default Briefcase
