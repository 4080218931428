import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { debounce } from '../../helper/Debounce'
import { useGetNewsQuery } from '../../redux/api/newsApi/newsApi.js'

import FilterListIcon from '@mui/icons-material/FilterList'
import InputAdornment from '@mui/material/InputAdornment'
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import colors from '../../Colors.scss'
import QuestionIcon from '../../SVG/global/QuestionIcon'
import SearchIcon from '../../SVG/global/SearchIcon'
import NewsIcon from '../../SVG/pages/News/News'
import ContextHelp from '../../components/ContextHelp/ContextHelp'
import '../../pages/Posters/Posters.scss'
import { filterMenu } from './NewsDetails/filterMenu.js'

export const News = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const posterId = location.pathname.split('/').slice(-3)[0]
  const isPosterDetail =
    window.location.href.includes('news') &&
    window.location.href.includes('details')

  const [char, setChar] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [order, setOrder] = useState('')
  const [orderBy, setOrderBy] = useState('-id')
  const [limit, setLimit] = useState(1000)
  const [showModalHelper, setShowModalHelper] = useState({
    open: false,
    event: {}
  })

  const slug = useSelector(state => state.globalValueReducer.slug)
  const eventId = useSelector(state => state.globalValueReducer.eventId)
  const { data: news, refetch } = useGetNewsQuery({
    eventId,
    searchValue,
    orderBy,
    order,
    limit
  })

  const handleSortRequest = cellId => {
    const isAsc = orderBy === cellId && order === ''
    setOrder(isAsc ? '-' : '')
    setOrderBy(cellId)
    refetch()
  }

  const handleFilterMenu = e => {
    handleSortRequest(e.label)
  }

  const updateSearchValue = useCallback(
    debounce(str => {
      setSearchValue(str)
    }, 1000),
    []
  )

  const handleSearch = async e => {
    setChar(e.target.value)
    updateSearchValue(e.target.value)
  }

  return (
    <div className="poster-wrap">
      <div className="title-main">
        <div className="title relative">
          <NewsIcon width="30" height="30" color={colors.color_white} />
          <p>
            <span className="help_span"
              onClick={e => {
                setShowModalHelper({
                  open: !showModalHelper.open,
                  event: e.currentTarget
                })
              }}
            >
              {t('sidebar.news')}
              <QuestionIcon width={'14'} height={'14'}/>
              {showModalHelper && (
                <ContextHelp
                  helpText={{
                    id: 1,
                    what: t('pages.news.context-help-news')
                  }}
                  open={showModalHelper.open}
                  anchorEl={showModalHelper.event}
                  setShowModalHelper={setShowModalHelper}
                />
              )}
            </span>
          </p>
        </div>
      </div>
      <div className="search">
        {window.screen.width > 1024 ? (
          <>
            <TextField
              sx={{
                '& label': { paddingLeft: theme => theme.spacing(1) },
                '& input': { paddingLeft: theme => theme.spacing(1) },
                '& fieldset': {
                  paddingLeft: theme => theme.spacing(2),
                  borderRadius: '30px'
                },
                width: '300px',
                background: colors.color_cards_background,
                borderRadius: '30px',
                marginBottom: '20px'
              }}
              size="small"
              margin="normal"
              type="text"
              value={char}
              placeholder={t('pages.posters.placeholder-search')}
              onChange={e => {
                handleSearch(e)
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              variant="outlined"
            />
            <div>
              <div className="participants-filter">
                <Popover
                  open={Boolean(anchorEl)}
                  id={anchorEl ? 'simple-popover-posters' : undefined}
                  onClose={() => setAnchorEl(null)}
                  onClick={() => {
                    setAnchorEl(!anchorEl)
                  }}
                  anchorOrigin={{
                    vertical: 260,
                    horizontal: 'right'
                  }}
                >
                  <p className="filter-menu-head">Sort by</p>
                  {filterMenu?.map(menu => (
                    <p
                      key={menu?.id}
                      className="filter-menu"
                      onClick={() => {
                        handleFilterMenu(menu)
                        setAnchorEl(null)
                      }}
                    >
                      {t(`pages.news.${menu?.name}`)}
                    </p>
                  ))}
                </Popover>
                <FilterListIcon
                  onClick={e => setAnchorEl(e.currentTarget)}
                  fontSize="medium"
                />
                {order === '' ? (
                  <ArrowDownwardIcon
                    onClick={() => {
                      setOrder('-')
                      refetch()
                    }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    onClick={() => {
                      setOrder('')
                      refetch()
                    }}
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <TextField
              sx={{
                '& label': { paddingLeft: theme => theme.spacing(1) },
                '& input': {
                  paddingLeft: theme => theme.spacing(1),
                  zIndex: '2',
                  fontFamily: 'Inter',
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '17px'
                },
                '& fieldset': {
                  paddingLeft: theme => theme.spacing(2),
                  borderRadius: '30px'
                },
                width: '100%',
                background: colors.color_cards_background,
                borderRadius: '30px',
                marginBottom: '20px'
              }}
              size="small"
              margin="normal"
              type="text"
              value={char}
              placeholder={t('pages.posters.placeholder-search-posters')}
              onChange={e => {
                handleSearch(e)
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <div>
                    <div className="participants-filter">
                      <Popover
                        open={Boolean(anchorEl)}
                        id={anchorEl ? 'simple-popover-posters' : undefined}
                        onClose={() => setAnchorEl(null)}
                        onClick={() => {
                          setAnchorEl(!anchorEl)
                        }}
                        anchorOrigin={{
                          vertical: 260,
                          horizontal: 'right'
                        }}
                      >
                        <p className="filter-menu-head">
                          {t('pages.posters.sort-by')}
                        </p>
                        {filterMenu?.map(menu => (
                          <p
                            key={menu?.id}
                            className="filter-menu"
                            onClick={() => {
                              handleFilterMenu(menu)
                              setAnchorEl(null)
                            }}
                          >
                            {t(`pages.news.${menu?.name}`)}
                          </p>
                        ))}
                      </Popover>
                      <FilterListIcon
                        onClick={e => setAnchorEl(e.currentTarget)}
                        fontSize="medium"
                      />

                      {order === '' ? (
                        <ArrowDownwardIcon
                          onClick={() => {
                            setOrder('-')
                            refetch()
                          }}
                        />
                      ) : (
                        <ArrowUpwardIcon
                          onClick={() => {
                            setOrder('')
                            refetch()
                          }}
                        />
                      )}
                    </div>
                  </div>
                )
              }}
              variant="outlined"
            ></TextField>
            <div
              onClick={e => {
                setShowModalHelper({
                  open: !showModalHelper.open,
                  event: e.currentTarget
                })
              }}
            >
              <QuestionIcon
                width="25"
                height="25"
                color={colors.color_grey_shade}
              />
              <ContextHelp
                helpText={{
                  id: 1,
                  what: t('pages.posters.context-help-posters')
                }}
                open={showModalHelper.open}
                anchorEl={showModalHelper.event}
                setShowModalHelper={setShowModalHelper}
              />
            </div>
          </>
        )}
      </div>
      <div className="posters-main">
        {!!news &&
          news.items.map(news => {
            const authors = news.authors.split(';').splice(0, 3).join()
            return (
              <div
                key={news.id}
                style={{
                  backgroundColor:
                    +posterId === +news.id
                      ? colors.highlighted_agenda
                      : colors.color_cards_background,
                  color:
                    +posterId === +news.id
                      ? colors.color_white
                      : colors.color_black
                }}
                className="poster-card"
              >
                <div
                  className={`${
                    +posterId === +news.id
                      ? 'navigate-posters-details-active '
                      : 'navigate-posters-details '
                  }`}
                  onClick={() => {
                    navigate(
                      `/events/${eventId}/${slug}/news/${news.id}/details/`
                    )
                  }}
                >
                  {!!news.title && <h4>{news.title}</h4>}
                  {!!news.short_description && (
                    <div className="short_description-posters">
                      {news.short_description}
                    </div>
                  )}
                  {!!news.authors && (
                    <div className="poster-authors">
                      <p
                        className="poster-authors-p"
                        style={{
                          color:
                            +posterId === +news.id
                              ? colors.highlighted_agenda_text_color
                              : colors.color_silver_icons
                        }}
                      >
                        {news.authors.split(';').length > 3 ? (
                          <>
                            {authors}
                            <span
                              className={`${
                                +posterId === news?.id
                                  ? 'span-white'
                                  : 'span-black'
                              }`}
                            >
                              {' '}
                              + {news.authors.split(';').length - 3} more
                            </span>
                          </>
                        ) : (
                          authors
                        )}
                      </p>
                    </div>
                  )}
                  {!!news.updated_at && (
                    <p className="poster-date">
                      {new Date(news.updated_at).toLocaleDateString('en-US', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                      })}
                    </p>
                  )}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}
