import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const defaultData = {
  defaultLanguage: 'en',
  useBrowserLanguage: true
}

const defaultLanguage =
  process.env.REACT_APP_DEFAULT_LANGUAGE || defaultData.defaultLanguage
const useBrowserLanguage =
  process.env.REACT_APP_USE_BROWSER_LANGUAGE || defaultData.useBrowserLanguage
const langFromBackend = localStorage.getItem('language')
const availableLanguages = process.env.REACT_APP_AVAILABLE_LANGUAGES.split(',')

export const languageMap = {
  de: 'Deutsch',
  en: 'English'
}

const getSupportedBrowserLanguage = () => {
  let browserLanguage = navigator.language || navigator.userLanguage

  browserLanguage = browserLanguage.split('-')[0]

  const isSupported = availableLanguages.includes(browserLanguage)

  return isSupported && useBrowserLanguage === 'true' ? browserLanguage : ''
}

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng:
      langFromBackend !== 'undefined' &&
      !!langFromBackend &&
      availableLanguages.includes(langFromBackend)
        ? langFromBackend
        : getSupportedBrowserLanguage() || defaultLanguage,
    debug: true,
    detection: {
      order: [
        'queryString',
        'cookie',
        'localStorage',
        'sessionStorage'
        // 'navigator',
        // 'htmlTag',
        // 'path',
        // 'subdomain'
      ],
      caches: ['localStorage', 'cookie']
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json'
    }
  })

export default i18n
