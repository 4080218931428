import React from 'react'
import colors from '../../Colors.scss'

const CircleFilled = ({ width = '24', height = '24', color = colors.color_sidebar_icon }) => {
  return (
     <svg
            width={width}
            height={height}
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
         <circle cx="4" cy="4" r="4" fill={color}/>
     </svg>

  )
}

export default CircleFilled
