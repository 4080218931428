import i18n from '../../i18n'

export const DayTranslate = ({ day, short }) => {
  if (day === 'Mon' || day === 'Monday') {
    if (short) { return i18n.t('parser.mon') } else { return i18n.t('parser.monday') }
  }
  if (day === 'Tue' || day === 'Tuesday') {
    const a = i18n.t('parser.tue')
    if (short) { return i18n.t('parser.tue') } else { return i18n.t('parser.tuesday') }
  }
  if (day === 'Wed' || day === 'Wednesday') {
    if (short) { return i18n.t('parser.wed') } else { return i18n.t('parser.wednesday') }
  }
  if (day === 'Thu' || day === 'Thursday') {
    if (short) { return i18n.t('parser.thu') } else { return i18n.t('parser.thursday') }
  }
  if (day === 'Fri' || day === 'Friday') {
    if (short) { return i18n.t('parser.fri') } else { return i18n.t('parser.friday') }
  }
  if (day === 'Sat' || day === 'Saturday') {
    if (short) { return i18n.t('parser.sat') } else { return i18n.t('parser.saturday') }
  }
  if (day === 'Sun' || day === 'Sunday') {
    if (short) { return i18n.t('parser.sun') } else { return i18n.t('parser.sunday') }
  }
}

export const MonthTranslate = ({ month }) => {
  const monthTranslated = month.split(' ')[0]
  if (monthTranslated === 'Jan') {
    return i18n.t('parser.jan')
  }
  if (monthTranslated === 'Feb') {
    return i18n.t('parser.feb')
  }
  if (monthTranslated === 'Mar') {
    return i18n.t('parser.mar')
  }
  if (monthTranslated === 'Apr') {
    return i18n.t('parser.apr')
  }
  if (monthTranslated === 'May') {
    return i18n.t('parser.may')
  }
  if (monthTranslated === 'June') {
    return i18n.t('parser.june')
  }
  if (monthTranslated === 'July') {
    return i18n.t('parser.july')
  }
  if (monthTranslated === 'Aug') {
    return i18n.t('parser.aug')
  }
  if (monthTranslated === 'Sep') {
    return i18n.t('parser.sep')
  }
  if (monthTranslated === 'Oct') {
    return i18n.t('parser.oct')
  }
  if (monthTranslated === 'Nov') {
    return i18n.t('parser.nov')
  }
  if (monthTranslated === 'Dec') {
    return i18n.t('parser.dec')
  }
}
