import { TextField } from '@material-ui/core'
import Alert from '@mui/material/Alert'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import DoneIcon from '../../SVG/global/DoneIcon'
import { useAuthValue } from '../../hooks/redux/reduxHooks'
import { useGetBannerEventsQuery } from '../../redux/api/homePageApi/homePageApi'
import { addEventId } from '../../redux/slices/globalValue/globalValue'

// src
import bg from '../../assets/cust/images/background.png'
import { GradiantBtn } from '../../assets/muiStyles/Button-style'
import { usePostSignUpMutation } from '../../redux/api/authApi/authApi'

export default function SignUpPage () {
  // hooks
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const { data: events } = useGetBannerEventsQuery()

  // env
  const refresh_token_expiration =
    process.env.REACT_APP_REFRESH_TOKEN_EXPIRATION

  // redux
  const evSlug = useSelector(state => state.globalValueReducer.slug)
  const eventId = useSelector(state => state.globalValueReducer.eventId)
  const refresh_token_expirationRedux = useSelector(
    state => state.globalValueReducer.refresh_token_expiration
  )
  const { userId } = useAuthValue()

  // state
  const [credientials, setCredientials] = useState({
    email: '',
    first_name: '',
    last_name: '',
    event_id: eventId
  })
  const [errorText, setError] = useState('')
  const [passwordEye, setPasswordEye] = useState(false)
  const locationEdit = location.pathname.split('/')
  const [postSignUp, isLoading] = usePostSignUpMutation(credientials)
  const [signUpSuccess, setSignUpSuccess] = useState(false)

  useEffect(() => {
    if (
      window.location.href.includes('/sign-in') ||
      (window.location.href.includes('/user/editing-profile/') &&
        refresh_token_expirationRedux === 'expired')
    ) {
      refresh_token_expirationRedux &&
        toast(t('pages.signinpage.toast-your-session-has-expired'), {
          toastId: 'session-expired-message'
        })
    }
  }, [])

  useEffect(() => {
    if (!events) return
    dispatch(addEventId({ eventId: events[0]?.id }))
  }, [events])

  const handleSubmit = async e => {
    e.preventDefault()
    if (
      credientials.email.length <= 0 ||
      credientials.first_name.length <= 0 ||
      credientials.last_name.length <= 0
    ) {
      return toast.error(t('pages.signinpage.toast-fields-required'), {
        position: 'top-center'
      })
    }
    postSignUp(credientials)
      .unwrap()
      .then(response => {
        if (response?.event_id === eventId) {
          setSignUpSuccess(true)
        }
      })
      .catch(e => {
        toast.error(e?.data?.non_field_errors?.[0] || e?.data?.[0], {
          position: 'top-center'
        })
      })
  }
  const handleChange = e => {
    setCredientials({ ...credientials, [e.target.name]: e.target.value })
  }

  if (userId || (!userId && process.env.REACT_APP_SIGNUP_ENABLED !== 'true')) {
    navigate('/')
  }

  if (signUpSuccess) {
    return (
      <div className="sign-up-success">
        <div>
          <DoneIcon width={'148'} />
          <h2 className="welcome-msg">
            {t('pages.signuppage.success-email-sent')}
          </h2>
          <p className="desc">{t('pages.signinpage.toast-account-created')}</p>
          <GradiantBtn
            style={{ width: '100%', marginTop: '12px' }}
            type="submit"
            loading={isLoading}
            variant="contained"
            onClick={() => {
              navigate('/sign-in')
            }}
          >
            {t('header.login')}
          </GradiantBtn>
        </div>
      </div>
    )
  }

  return (
    <>
      <ToastContainer />
      <div className="sign-in-main" style={{ position: 'relative' }}>
        <img
          src={bg}
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: '0'
          }}
          alt=""
        />
        <form onSubmit={handleSubmit} noValidate>
          <div
            className="sign-in-form"
            style={{ zIndex: '11', position: 'relative' }}
          >
            <p className="welcome-msg">{t('pages.signinpage.welcome')}</p>
            <p className="desc">{t('pages.signinpage.create-an-account')}</p>

            <TextField
              style={{ marginTop: '50px' }}
              error={!!errorText}
              // helperText= 'Please Enter Your Email'
              value={credientials.email}
              onFocus={() => setError('')}
              onChange={e => handleChange(e)}
              placeholder={t(
                'pages.signinpage.placeholder-enter-your-email-address'
              )}
              type="email"
              name="email"
              variant="outlined"
              required
            />

            <TextField
              style={{ marginTop: '24px', borderRadius: '8px' }}
              error={!!errorText}
              // helperText= 'Please Enter Your Email'
              value={credientials.first_name}
              onFocus={() => setError('')}
              onChange={e => handleChange(e)}
              placeholder={t(
                'pages.signinpage.placeholder-enter-your-first-name'
              )}
              type="test"
              name="first_name"
              variant="outlined"
              required
            />

            <TextField
              style={{ marginTop: '24px', borderRadius: '8px' }}
              error={!!errorText}
              // helperText= 'Please Enter Your Email'
              value={credientials.last_name}
              onFocus={() => setError('')}
              onChange={e => handleChange(e)}
              placeholder={t(
                'pages.signinpage.placeholder-enter-your-last-name'
              )}
              type="test"
              name="last_name"
              variant="outlined"
              required
            />

            <br />
            {errorText ? (
              <Alert
                className="error-message"
                style={{}}
                variant="outlined"
                severity="error"
              >
                {errorText}
              </Alert>
            ) : (
              ''
            )}
            <div className="sign-in-option">
              <p
                className="forgot-pass"
                onClick={() => {
                  navigate('/sign-in')
                }}
              >
                {t('pages.signinpage.already-have-an-account')}
              </p>
              <p
                className="forgot-pass"
                onClick={() => {
                  navigate('/password/forgot')
                }}
              >
                {t('pages.signinpage.forgot-password')}
              </p>
            </div>
            <GradiantBtn type="submit" loading={isLoading} variant="contained">
              {t('pages.signuppage.create-account-button')}
            </GradiantBtn>
          </div>
          <br />
        </form>
        <div className="details-container">
          <div className="homepage-section-2-text">
            <p className="heading">{t('pages.signinpage.page-heading')}</p>
            <p className="para">{t('pages.signinpage.page-para')}</p>
          </div>
        </div>
      </div>
    </>
  )
}
