export const filterMenu = [
  {
    id: 1,
    label: 'title',
    name: 'sort-by-title'
  },
  {
    id: 2,
    label: 'authors',
    name: 'sort-by-authors'
  },
  {
    id: 3,
    label: 'updated_at',
    name: 'sort-by-date'
  }
]
